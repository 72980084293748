import { RootState } from '../states/root.state'
import { createSelector } from '@ngrx/store'
import { parseISOLangToSimplified } from '@core/utils/i18n.utils'

export const selectUser = (state: RootState) => state.user

export const selectProfile = createSelector(selectUser, (state) => state.profile)

export const selectUserApplicationsProfile = createSelector(selectUser, (user) => user.applications)

export const selectIsoLang = createSelector(selectProfile, (profile) => profile?.language)

export const selectSimplifiedLang = createSelector(
  selectProfile,
  (profile) => profile && parseISOLangToSimplified(profile?.language)
)

export const selectFavourites = createSelector(selectProfile, (profile) => profile?.favourites)

export const selectFavTeamId = createSelector(selectFavourites, (favourites) => favourites?.teamId)

export const selectFavCompetitionId = createSelector(selectFavourites, (favourites) => favourites?.competitionId)

export const selectUserIsLoading = createSelector(selectUser, (state) => state.loading)
