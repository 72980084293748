import { createAction, props } from '@ngrx/store'
import { UserProfile } from '@core/services/auth/auth.models'
import { ApplicationMenuModels } from '@mediacoach-ui-library/global'

export const updateUserCompetitionTeamSuccessType = '[Profile] Save user competition and team success'

export const fetchUserProfile = createAction('[Profile] Fetch user profile')
export const setProfile = createAction(
  '[Profile] Set profile and trigger side effects',
  props<{ profile: UserProfile }>()
)
export const setUserProfile = createAction('[Profile] Set user profile', props<{ profile: UserProfile }>())
export const setUserApplications = createAction(
  '[Profile] Set user applications',
  props<{ applications: ApplicationMenuModels[] }>()
)
export const saveUserProfile = createAction('[Profile] Save user profile', props<{ profile: UserProfile }>())
export const updateUserCompetitionTeam = createAction(
  '[Profile] Save user competition and team',
  props<{ competitionId: string; teamId: string }>()
)
export const updateUserCompetitionTeamSuccess = createAction(
  '[Profile] Save user competition and team success',
  props<{ profile: UserProfile }>()
)
export const purgeUserProfile = createAction('[Profile] Purge user profile')
export const setUserProfileLoading = createAction('[Profile] set loading', props<{ loading: boolean }>())
