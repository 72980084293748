import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Observable, throwError } from 'rxjs'
import { catchError, filter, map, switchMap, tap, } from 'rxjs/operators'
import { Action, Store } from '@ngrx/store'
import { HttpResponse } from '@angular/common/http'
import {
  fetchUserProfile,
  saveUserProfile,
  setProfile,
  setUserApplications,
  setUserProfile,
  setUserProfileLoading,
  updateUserCompetitionTeam,
  updateUserCompetitionTeamSuccess
} from '@core/store/actions/profile.actions'
import { UserProfile } from '@core/services/auth/auth.models'
import { signOut } from '@core/store/actions/auth.actions'
import { catchRequestError, HttpStatusCode } from '@mediacoach/ui'
import { ProfileApi } from '@core/requests/api/profile/profile.api'
import { parseProfile } from '@core/services/auth/auth.utils'
import { selectProfile } from '@core/store/selectors/user.selectors'
import { RouterEventsService } from '@core/services/router-events/router-events.service'
import { fetchTeamSummary } from '@pages/team/store/actions/team.actions'
import { fetchCurrentSeason } from '@core/store/actions/season.actions'
import { connectSocket } from '@core/store/actions/socket.actions'

@Injectable()
export class ProfileEffects {
  fetchProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchUserProfile),
      switchMap(() =>
        this._api.fetchUserProfile().pipe(
          map((res: HttpResponse<UserProfile>) => {
            if (res.status === HttpStatusCode.NoContent) {
              return signOut()
            }
            const profile = {...parseProfile(res.body as UserProfile)}
            this._routerEventsService.permissions = profile.permissions
            return setProfile({profile})
          }),
          tap(() => this._store.dispatch(fetchCurrentSeason())),
          catchError((err) => {
            return throwError(() => err)
          })
        )
      )
    )
  )

  setProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setProfile),
      switchMap(({profile}) => {
        return [
          setUserProfile({profile}),
          setUserApplications({
            applications: profile ? profile.profiles : [],
          }),
          connectSocket()
        ]
      })
    )
  )

  saveProfile$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserProfile),
      switchMap(({profile}) =>
        this._api.saveUserProfile(profile).pipe(
          map(() => setProfile({profile})),
          catchRequestError()
        )
      )
    )
  )

  updateUserCompetitionTeam$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(updateUserCompetitionTeam),
      filter(({competitionId, teamId}) => !!competitionId && !!teamId),
      concatLatestFrom(() => this._store.select(selectProfile)),
      map(([{competitionId, teamId}, profile]) => ({
        ...profile,
        favouriteCompetitionId: competitionId,
        favouriteTeamId: teamId,
        favourites: {...profile.favourites, competitionId, teamId},
      })),
      switchMap((profile: UserProfile) =>
        this._api.saveUserProfile(profile).pipe(
          tap(() => {
            this._store.dispatch(setProfile({profile}))
          }),
          map(() => profile),
          catchError((err) => {
            this._store.dispatch(setUserProfileLoading({ loading: false }))
            return throwError(() => err)
          })
        )
      ),
      switchMap((profile) => [
        fetchTeamSummary(),
        updateUserCompetitionTeamSuccess({profile}),
        setUserProfileLoading({loading: false})
      ])
    )
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _api: ProfileApi,
    private readonly _routerEventsService: RouterEventsService,
    private readonly _store: Store
  ) {
  }

}
