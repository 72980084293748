import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { setMetricHolder, setMetrics } from '@core/store/actions/metric-finder.actions'
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { mapComparedTeams } from '@pages/metric-finder/metric-finder.utils'
import {
  fetchFavTeamComparisonMetrics,
  fetchFavTeamMetrics,
  fetchTeamComparisonMetrics,
  fetchTeamHolder,
  fetchTeamMetrics,
  fetchTeamMetricsFinderData
} from '@core/store/actions/team-metric-finder.actions'
import { TeamApi } from '@core/requests/api/team/team.api'
import { selectFavCurrentSeasonCompetitionWithFavTeamId } from '@core/store/selectors/season.selector'
import { mapSummary, mapTeamsCompareParams } from '@pages/team/utils/team.utils'
import { TeamSummary } from '@pages/team/models/team.models'
import { requestErrorHandler } from '@shared/operators/request-error.operator'
import { selectMergedRoute } from '@core/ngrx-router/state/selectors/ngrx-router.selectors'

@Injectable()
export class TeamMetricFinderEffects {
  fetchPlayerMetricsFinderData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTeamMetricsFinderData),
      concatLatestFrom(() => this._store.select(selectMergedRoute)),
      map(([, { params} ]) => ({...params}) as any),
      map(({ vsCompetitionId, vsTeamId, seasonId }) =>
        {
          const isVsEnabled = !!vsCompetitionId && !!vsTeamId
          if (isVsEnabled) {
            return fetchFavTeamComparisonMetrics({
              seasonIdB: seasonId,
              competitionIdB: vsCompetitionId,
              teamIdB: vsTeamId
            })
          } else {
            return fetchFavTeamMetrics()
          }
        }
      )
    )
  )

  fetchTeamHolder$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTeamHolder),
      switchMap(({ season, competition, teamId }) =>
        this._teamApi.fetchTeam(season.id, competition.id, teamId).pipe(
          map(team => ({ ...team, season, competition } as TeamSummary)),
          requestErrorHandler()
        )
      ),
      map(team => mapSummary(team as TeamSummary)),
      map(metricHolder => setMetricHolder({ metricHolder }))
    )
  )

  fetchTeamMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTeamMetrics),
      switchMap(({ seasonId, competitionId, teamId }) =>
        this._teamApi.fetchTeamMetrics(seasonId, competitionId, teamId).pipe(requestErrorHandler())
      ),
      map(metrics => setMetrics({ metrics }))
    )
  )

  fetchFavTeamMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchFavTeamMetrics),
      switchMap(() => this._store.pipe(selectFavCurrentSeasonCompetitionWithFavTeamId()).pipe(take(1))),
      filter(({ teamId }) => !!teamId),
      mergeMap(({ season, competition, teamId }) => [
        fetchTeamHolder({ season, competition, teamId }),
        fetchTeamMetrics({ seasonId: season.id, competitionId: competition.id, teamId })
      ])
    )
  )

  fetchFavTeamComparisonMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchFavTeamComparisonMetrics),
      switchMap(({ type: _, ...params}) => this._store.pipe(selectFavCurrentSeasonCompetitionWithFavTeamId()).pipe(
        take(1),
        map((data) => ({...data, ...params}))
      )),
      filter(({ teamId, ...params }) => !!teamId && !!params),
      map(({ season, competition, teamId, ...params }) =>
        mapTeamsCompareParams({ competition, season, teamId, ...params })
      ),
      map(toCompare => fetchTeamComparisonMetrics({ toCompare }))
    )
  )

  fetchTeamComparisonMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTeamComparisonMetrics),
      switchMap(({ toCompare }) =>
        this._teamApi.compare(toCompare).pipe(
          map(({ teamA, teamB, ...metrics }) => ({
            ...metrics,
            teamA: { ...teamA, ...toCompare.teamA },
            teamB: { ...teamB, ...toCompare.teamB }
          })),
          requestErrorHandler()
        )
      ),
      mergeMap(({ teamA, teamB, ...metrics }) => [
        setMetricHolder({ metricHolder: mapComparedTeams({ teamA, teamB }) }),
        setMetrics({ metrics })
      ])
    )
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _teamApi: TeamApi
  ) {}
}
