import { Action, createReducer, on } from '@ngrx/store'
import { UserState } from '@core/store/states/user.state'
import {
  purgeUserProfile,
  setUserApplications,
  setUserProfile,
  setUserProfileLoading
} from '@core/store/actions/profile.actions'

const initialState: UserState = {
  profile: null,
  applications: null,
  loading: null
}

const reducer = createReducer(
  initialState,
  on(
    setUserProfile,
    (state: UserState, { profile }): UserState => ({
      ...state,
      profile: {
        ...state.profile,
        ...profile,
        ...(profile && profile.favourites
          ? { favouriteTeamId: profile.favourites.teamId, favouriteCompetitionId: profile.favourites.competitionId }
          : {}),
        ...(profile?.photoPath ? { avatar: profile.photoPath } : {})
      }
    })
  ),
  on(setUserApplications, (state, { applications }): UserState => ({ ...state, applications })),
  on(setUserProfileLoading, (state, { loading }): UserState => ({ ...state, loading })),
  on(purgeUserProfile, (): UserState => ({ ...initialState }))
)

export const userReducer = (state: UserState | undefined, action: Action): UserState => reducer(state, action)
