import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { setMetricHolder, setMetrics } from '@core/store/actions/metric-finder.actions'
import { map, switchMap, take, tap } from 'rxjs/operators'
import { MetricsApi } from '@core/services/metric-finder/metrics.api'
import { Store } from '@ngrx/store'
import {
  fetchFavTeamPlayerMetrics,
  fetchMatchPlayerComparisonMetrics,
  fetchMatchPlayerMetrics,
  fetchPlayerComparisonMetrics,
  fetchPlayerMetrics,
  fetchPlayerMetricsFinderData
} from '@core/store/actions/player-metric-finder.actions'
import { parseComparedPlayers } from '@pages/matches/constants/matches.constants'
import { mapComparisonResult, mapPlayer } from '@core/utils/player.utils'
import { requestErrorHandler } from '@shared/operators/request-error.operator'
import { selectCurrentSeasonCompetitionAndTeamIds } from '@core/store/selectors/season.selector'
import { TeamApi } from '@core/requests/api/team/team.api'
import { mapSummary } from '@pages/team/utils/team.utils'
import { TeamSummary } from '@pages/team/models/team.models'
import { PlayerApi } from '@core/requests/api/player/player.api'
import { PlayerSummary } from '@pages/player-detail/models/player-detail.models'
import { Params } from '@angular/router'
import { selectMergedRoute } from '@core/ngrx-router/state/selectors/ngrx-router.selectors'
import { preparePlayerComparison } from '@pages/metric-finder/metric-finder.utils'

@Injectable()
export class PlayerMetricFinderEffects {

  fetchPlayerMetricsFinderData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlayerMetricsFinderData),
      concatLatestFrom(() => this._store.select(selectMergedRoute)),
      map(([, { params, queryParams } ]) => ({params, position: queryParams?.position})),
      map(({ params, position}) =>
        {
          const isMatch = !!params.matchId
          const isVsEnabled = !!params.vsPlayerId
          if (isVsEnabled) {
            return this._resolvePlayerComparison(params, position, isMatch)
          } else {
            return this._resolveSinglePlayer(params, position, isMatch)
          }
        }
      )
    )
  )

  fetchFavTeamPlayerMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchFavTeamPlayerMetrics),
      switchMap(({ playerId, position }) => this._store.pipe(
        selectCurrentSeasonCompetitionAndTeamIds()
      ).pipe(
          take(1),
          map((data) => ({...data, playerId, position}))
        )
      ),
      switchMap(({playerId, position, seasonId, competitionId, teamId}) =>
        this._playerApi.fetchPlayer(playerId, seasonId, competitionId, teamId).pipe(
          map(
            summary =>
              ({
                ...summary,
                playerId,
                metricPlayerPosition: position,
                teamId,
                seasonId,
                competitionId
              } as PlayerSummary)
          ),
          requestErrorHandler()
        )
      ),
      switchMap(summary =>
        this._teamApi.fetchTeam(summary.seasonId, summary.competitionId, summary.teamId).pipe(
          map(team => ({...mapPlayer(summary), team: mapSummary(team as TeamSummary)} as PlayerSummary)),
          requestErrorHandler()
        )
      ),
      map((player: any) => fetchPlayerMetrics({ player }))
    )
  )

  fetchPlayerMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlayerMetrics),
      tap(({ player }) => this._store.dispatch(setMetricHolder({ metricHolder: player }))),
      switchMap(({ player }) =>
        this._api.fetchPlayerMetrics(player.seasonId, player.competitionId, player.teamId, player.id, player.metricPlayerPosition).pipe(requestErrorHandler())
      ),
      map(metrics => setMetrics({ metrics }))
    )
  )

  fetchPlayerComparisonMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlayerComparisonMetrics),
      switchMap(({ players }) =>
        this._api.fetchPlayerComparisonMetrics(players).pipe(
          map(data => mapComparisonResult(data)),
          map(({ playerA, playerB, ...metrics }) => ({
            ...metrics,
            playerA: { ...playerA, ...players.playerA },
            playerB: { ...playerB, ...players.playerB }
          })),
          map(({ playerA, playerB, ...metrics }) => {
            this._store.dispatch(setMetricHolder({ metricHolder: { playerA, playerB } }))
            return metrics
          }),
          requestErrorHandler()
        )
      ),
      map(metrics => setMetrics({ metrics }))
    )
  )

  fetchMatchPlayerMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchPlayerMetrics),
      switchMap(({ matchId, teamId, playerId, position }) =>
        this._api.fetchMatchPlayerMetrics(matchId, teamId, playerId, position).pipe(
          tap(data => this._store.dispatch(setMetricHolder({ metricHolder: mapPlayer(data) }))),
          map(metrics => setMetrics({ metrics })),
          requestErrorHandler()
        )
      )
    )
  )

  fetchMatchPlayerComparisonMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchPlayerComparisonMetrics),
      switchMap(({ matchId, comparison, position }) =>
        this._teamApi.fetchTeam(
          comparison?.playerA?.seasonId,
          comparison?.playerA?.competitionId,
          comparison?.playerA?.teamId
        ).pipe(map((team) => ({ team: mapSummary(team as TeamSummary), matchId, position, comparison}))),
      ),
      switchMap(({ matchId, comparison, position, team }) =>
        this._api.fetchMatchPlayerComparisonMetrics(matchId, comparison, position).pipe(map((compared) =>
          parseComparedPlayers(compared, { ...comparison.playerA, team }, comparison.playerB)))
      ),
      map(({ playerA, playerB, ...metrics }) => {
        this._store.dispatch(setMetricHolder({ metricHolder: { playerA, playerB } }))
        return setMetrics({ metrics })
      })
    )
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _teamApi: TeamApi,
    private readonly _playerApi: PlayerApi,
    private readonly _api: MetricsApi
  ) {}

  private _resolvePlayerComparison(params: Params, position: string, isMatch: boolean) {
    const { players, matchId } = preparePlayerComparison(params, position)

    if (isMatch) {
      return fetchMatchPlayerComparisonMetrics({ matchId, comparison: players, position })
    } else {
      return fetchPlayerComparisonMetrics({ players })
    }
  }

  private _resolveSinglePlayer(params: Params, position: string, isMatch: boolean) {
    const { matchId, teamId, id } = params

    if (isMatch) {
      return fetchMatchPlayerMetrics({ matchId, teamId, playerId: id, position })
    } else {
      return fetchFavTeamPlayerMetrics({ playerId: id, position })
    }
  }
}

